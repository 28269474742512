function generate_og() {
    // Hilfsfunktion, um die URL-Parameter zu parsen
    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has(param);
    }

    // Prüfen, ob der GET-Parameter "generate_og" existiert
    if (getQueryParam('generate_og')) {
        const htmlElement = document.documentElement; // Das <html>-Element

        // Klasse "generate_og" hinzufügen, falls sie noch nicht vorhanden ist
        if (!htmlElement.classList.contains('generate_og')) {
            htmlElement.classList.add('generate_og');
        }
    }
}

// Funktion beim Laden der Seite ausführen
document.addEventListener('DOMContentLoaded', generate_og);
