// Wollte ts-check hinzufügen und die unteren eslint disable entfernen die vor über 2 Jahren von Tim hinzugefügt wurden. Aber der Aufwand ist es nicht wert, da es sich um ein Dok handelt, der von Grund auf optimiert werden muss. Habe ein paar Abfragen hinzugefügt, um sicher zu gehen, dass keine undefined oder null vorkommen.

/* eslint-disable*/

import Swiper, { Pagination } from 'swiper';

const priceBoxSlider = () => {
    // count all priceboxes
    const sliderPriceBox = document.querySelectorAll('.swiper.pricebox');
    /*  get all priceboxes*/
    const sliderPriceCardSwiperSlide = document.querySelectorAll('.pricecard.swiper-slide');
    /* The node number that contains the element. */
    let number = 0;
    /*  is used to search for an element in the list of child elements (children) of a parent element (parent) in DOM.  */
    if (sliderPriceCardSwiperSlide) {
        /* get first highlighted pricebox  */
        const child = document.querySelector('.highlight');
        /* Hero not preselected in mobile view. */
        // const sliderPricenotHighlight = document.querySelector('.swiper-slide-active');
        if (child) {
            const parent = child.parentNode;
            if (parent) {
                number = Array.prototype.indexOf.call(parent.children, child);

                /* It is checked whether the current "element" has the CSS class 'd-none'.     */
                /* We count how many 'd-none' elements are located before the 'highlight' class. */
                /* We recalculated the 'number' variable. */
                let minus_count = 0;
                let count = 0;
                /* select all the elements with class d-none  from the previous box before highlighted */
                const sliderPriceCardVLZ12Conditions = document.querySelector('.pricecard[data-vlz="12"].swiper-slide.d-none');

                if (sliderPriceCardVLZ12Conditions) {
                    Array.from(parent.children).forEach((element) => {
                        if (count < number) {
                            if (element.classList.contains('d-none')) {
                                minus_count++;
                            }
                            count++;
                        }
                    });
                    number = number - minus_count;
                }
            }
        } else { /* There is no preselected hero in the mobile view  */
            number = 0;
        }
    }
    // get all VLZ12 priceboxes
    const sliderPriceCardVLZ12 = document.querySelectorAll('.swiper .pricecard[data-vlz="12"]:not([data-mail]):not(.swiper-slide-duplicate)');

    // add data attribute to all VLZ12 priceboxes
    let countSliderPriceCardVLZ12 = 0;
    sliderPriceCardVLZ12.forEach(element => {
        // @ts-ignore
        element.dataset.vlz12 = countSliderPriceCardVLZ12++;
    });

    // is needed to fade in pricebox after init, to avoid "flickering" while loading and focus highlighted pricecard
    const sliderPriceBoxWrapper = document.querySelectorAll(
        '.pricebox__wrapper'
    );

    // counts all pricecards
    const sliderPriceCard = document.querySelectorAll('.pricecard');
    let p = 0;
    for (p = 0; p < sliderPriceCard.length; p++) {
        // needed to show tooltips over next pricecard. otherwise the tooltips can be hidden by next pricecard.
        // @ts-ignore
        sliderPriceCard[p].style['z-index'] = sliderPriceCard.length - p;
    }

    const hasCls5 = document.querySelectorAll('.pricecard.cls-5');
    const swiperWrapperCollection = document.querySelectorAll('.swiper-wrapper');
    const priceboxWrapperCollection = document.querySelectorAll('.pricebox__wrapper');

    // check if there are at least 5 pricecards
    if (hasCls5.length >= 1) {

        //body has data-device="xxl"
        if (document.body.dataset.device === 'xxl') {
            // add width: 293.6px; margin-right: 15px; to all elements with following classes: pricecard swiper-slide
            const pricecardSwiperSlide = document.querySelectorAll('.pricecard.swiper-slide');
            pricecardSwiperSlide.forEach(element => {
                // @ts-ignore
                element.style.width = '293.6px';
                // @ts-ignore
                element.style.marginRight = '15px';
            });
        }
        swiperWrapperCollection.forEach((swiperWrapper, index) => {
            swiperWrapper.classList.add('includes-cls-5');
        });
        priceboxWrapperCollection.forEach((priceboxWrapper, index) => {
            priceboxWrapper.classList.add('includes-cls-5');
        });

        // more than one pricebox? let's check and add a specific class
        let i = 0;
        for (i = 0; i < sliderPriceBox.length; i++) {
            sliderPriceBox[i].classList.add('pricebox-' + i);
            // https://swiperjs.com
            let priceBox = new Swiper('.pricebox-' + i, {
                modules: [Pagination],
                spaceBetween: 15,
                slidesPerView: 'auto',
                loop: false,
                centeredSlides: true,
                grabCursor: false,
                // Focus Highlight Slide on Mobile
                initialSlide: number,
                pagination: {
                    el: '.swiper-pagination_pricebox',
                    clickable: true
                },
                breakpoints: {
                    400: {
                        slidesPerView: 'auto',
                    },
                    580: {
                        slidesPerView: 'auto',
                    },
                    640: {
                        slidesPerView: 'auto',
                    },
                    768: {
                        slidesPerView: 3,
                        allowTouchMove: true,
                    },
                    992: {
                        slidesPerView: 4,
                        allowTouchMove: true,
                        centeredSlides: false,
                    },
                    1300: {
                        slidesPerView: 'auto',
                        allowTouchMove: true,
                        centeredSlides: false,
                    },
                    1550: {
                        slidesPerView: 5,
                        allowTouchMove: true,
                        centeredSlides: false,
                    },
                },
                on: {
                    // on init
                    init: function () {
                        // fade in slider after highlighted pricecard is focused
                        setTimeout(() => {
                            // check if class exists
                            sliderPriceBoxWrapper.forEach(element => {
                                element.classList.add('fadeIn');
                            });
                        }, 5);
                    },
                },
            });
        }
    }

    // check if there are less than 5 pricecards
    if (hasCls5.length < 1) {
        swiperWrapperCollection.forEach((swiperWrapper, index) => {
            swiperWrapper.classList.add('includes-no-cls-5');
        });
        priceboxWrapperCollection.forEach((priceboxWrapper, index) => {
            priceboxWrapper.classList.add('includes-no-cls-5');
        });

        // more than one pricebox? let's check and add a specific class
        let i = 0;
        for (i = 0; i < sliderPriceBox.length; i++) {
            sliderPriceBox[i].classList.add('pricebox-' + i);
            // https://swiperjs.com
            let priceBox = new Swiper('.pricebox-' + i, {
                modules: [Pagination],
                spaceBetween: 15,
                slidesPerView: 'auto',
                loop: false,
                centeredSlides: true,
                grabCursor: false,
                // Focus Highlight Slide on Mobile
                initialSlide: number,
                pagination: {
                    el: '.swiper-pagination_pricebox',
                    clickable: true
                },
                breakpoints: {
                    400: {
                        slidesPerView: 'auto',
                    },
                    580: {
                        slidesPerView: 'auto',
                    },
                    640: {
                        slidesPerView: 'auto',
                    },
                    768: {
                        slidesPerView: 3,
                        allowTouchMove: true,
                        centeredSlides: false,
                    },
                    1200: {
                        slidesPerView: 'auto',
                        allowTouchMove: true,
                    },
                },
                on: {
                    // on init
                    init: function () {
                        // fade in slider after highlighted pricecard is focused
                        setTimeout(() => {
                            // check if class exists
                            sliderPriceBoxWrapper.forEach(element => {
                                element.classList.add('fadeIn');
                            });
                        }, 5);
                    },
                },
            });
        }
    }

    priceCardLastChildMargin();

    function priceCardLastChildMargin() {
        // get last pricecard with vlz 1
        const priceCardVLZ1LastChild = Array.from(
            document.querySelectorAll('.table-feature-wrapper .pricecard[data-vlz="1"]')
        ).pop();

        // get last pricecard with vlz 12
        const priceCardVLZ12LastChild = Array.from(
            document.querySelectorAll('.table-feature-wrapper .pricecard[data-vlz="12"]')
        ).pop();

        if (priceCardVLZ1LastChild) {
            if (window.matchMedia('(min-width: 768px)').matches) {
                // set margin of last pricecards to 0
                // @ts-ignore
                priceCardVLZ1LastChild.style.marginRight = '0';
                if (priceCardVLZ12LastChild) {
                    // @ts-ignore
                    priceCardVLZ12LastChild.style.marginRight = '0';
                }

                // if last pricecard has a dropdown, all this pricecards are last element when visible
                if (priceCardVLZ1LastChild.hasAttribute('data-name')) {
                    // @ts-ignore
                    const priceCardDataName = priceCardVLZ1LastChild.dataset.name;
                    const priceCardWithDropdown = document.querySelectorAll('.table-feature-wrapper .pricecard[data-name="' + priceCardDataName + '"]');
                    priceCardWithDropdown.forEach(element => {
                        // @ts-ignore
                        element.style.marginRight = '0';
                    });
                }
            } else {
                // set margin of last pricecards to 15px
                // @ts-ignore
                priceCardVLZ1LastChild.style.marginRight = '15px';
                if (priceCardVLZ12LastChild) {
                    // @ts-ignore
                    priceCardVLZ12LastChild.style.marginRight = '15px';
                }

                // if last pricecard has a dropdown, all this pricecards are last element when visible
                if (priceCardVLZ1LastChild.hasAttribute('data-name')) {
                    // @ts-ignore
                    const priceCardDataName = priceCardVLZ1LastChild.dataset.name;
                    const priceCardWithDropdown = document.querySelectorAll('.table-feature-wrapper .pricecard[data-name="' + priceCardDataName + '"]');
                    priceCardWithDropdown.forEach(element => {
                        // @ts-ignore
                        element.style.marginRight = '15px';
                    });
                }
            }
        }
    }

    //resize listener
    window.addEventListener('resize', function () {
        priceCardLastChildMargin();
        if (hasCls5.length >= 1) {

        setPriceCardWidths();
    }
    });
};

document.readyState === 'complete' ? priceBoxSlider() : window.addEventListener('load', priceBoxSlider);


// const data-vlz="12" pricecard
// const data-vlz="1" pricecard

// all pricecards with data-vlz="12"

const priceCardVLZ12 = document.querySelectorAll('.pricecard[data-vlz="12"]');
const priceCardVLZ1 = document.querySelectorAll('.pricecard[data-vlz="1"]');

// on document ready


window.addEventListener('load', () => {
    // check if hasCls5 exists
    const hasCls5 = document.querySelectorAll('.pricecard.cls-5');
    // check if there are at least
    if (hasCls5 && hasCls5.length >= 1) {


       setPriceCardWidths();

    }
});
    function setPriceCardWidths() {
        const priceCardVLZ12LastChild = Array.from(document.querySelectorAll('.container > .pricebox__wrapper .pricecard[data-vlz="12"]')).pop();

        // width of priceCardVLZ12LastChild
        const priceCardVLZ12LastChildWidth = priceCardVLZ12LastChild.offsetWidth;

        const priceCardVLZ1 = document.querySelectorAll('.container > .pricebox__wrapper .pricecard[data-vlz="1"]');

        // set the width to all pricecards with data-vlz="12"
        priceCardVLZ1.forEach(element => {
            element.style.width = priceCardVLZ12LastChildWidth + 'px';
        });
    }
