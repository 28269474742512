// Theme Switcher Module
export const themeSwitcher = {
    // Initialize the theme from localStorage
    init() {
        const savedTheme = localStorage.getItem('strato-theme');
        if (savedTheme) {
            this.setTheme(savedTheme);
        }
        return this.getCurrentTheme();
    },

    // Set a new theme
    setTheme(themeName) {
        document.documentElement.setAttribute('data-theme', themeName);
        localStorage.setItem('strato-theme', themeName);
        return themeName;
    },

    // Get current theme
    getCurrentTheme() {
        return document.documentElement.getAttribute('data-theme') ||
             localStorage.getItem('strato-theme') ||
             null;
    },

    // Toggle between themes (example with 'ozone' and another theme)
    toggleThemes(theme1 = 'ozone', theme2 = 'default') {
        const current = this.getCurrentTheme();
        const newTheme = current === theme1 ? theme2 : theme1;
        return this.setTheme(newTheme);
    }
};

// Initialize theme immediately when module loads
themeSwitcher.init();

// Optional: Listen for storage changes across tabs
window.addEventListener('storage', (event) => {
    if (event.key === 'strato-theme') {
        themeSwitcher.setTheme(event.newValue);
    }
});
