/* eslint-disable */
// @ts-check

var COOKIE_NAME = 'cookiecookie'; // name of consentcookie
var DEFAULT_PURPOSE = '1000';

var HTML_LANG = document.documentElement.lang;
var lastUpdated = '2023-02-09T11:50:00Z';
var cookieValueFallback = '0000-00-00T00:00:00Z_1000_';
/** @type {HTMLElement|null} */
var consentWrapper = document.querySelector('.consent__wrapper');
var LAYERS = [
    {name: 'default', id: 'consentLayerOne'},
    {name: 'settings', id: 'consentLayerTwo'}
];
var FOCUS_ITEMS = ['.btn--a11y', '.link--a11y', '.ele-checkbox:not([for="purpose1"])'];
var CONSENT_TXT = {
    'de': '/_assets/consent/consent_de.txt',
    'nl': '/_assets/consent/consent_nl.txt',
    'es': '/_assets/consent/consent_es.txt',
    'fr': '/_assets/consent/consent_fr.txt',
    'en': '/_assets/consent/consent_en.txt',
    'sv': '/_assets/consent/consent_sv.txt',
    'se': '/_assets/consent/consent_sv.txt',
};

var MOCK_RESPONSE = {
    "purposes":"1000",
    "data_cookie_value": lastUpdated + "_1000_1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,21,22,23,24,25,26,27,28,29,30,31,32,33",
    "data_cookie_consent":{
        "lastUpdated":lastUpdated,
        "vendors_as_list": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33"],
        "vendors":[{"purposes_ids":["2"],"id":1,"name":"swts"},{"purposes_ids":["2"],"id":2,"name":"matomo"},{"name":"econda","id":3,"purposes_ids":["2"]},{"purposes_ids":["2"],"name":"ab_test_portal","id":4},{"purposes_ids":["2"],"id":5,"name":"kameloon"},{"jentis_name":"google_analytics_4_server-side","name":"google_analytics","id":6,"purposes_ids":["4"]},{"purposes_ids":["4"],"name":"doubleclick","jentis_name":"floodlight","id":7},{"purposes_ids":["4"],"id":8,"jentis_name":"facebook","name":"facebook"},{"purposes_ids":["4"],"jentis_name":"gadr","name":"google_targeting","id":9},{"purposes_ids":["4"],"name":"google_conversion","jentis_name":"adwords","id":10},{"purposes_ids":["1"],"name":"session_faq_knd_login","id":11},{"purposes_ids":["1"],"name":"session_knd_login","id":12},{"purposes_ids":["1"],"name":"php_admin_knd_login","id":13},{"name":"cookie_hidrive","id":14,"purposes_ids":["1"]},{"purposes_ids":["1"],"name":"session_portal","id":15},{"name":"dwh_oauth","id":16,"purposes_ids":["1"]},{"id":17,"name":"lang_server_login","purposes_ids":["1"]},{"name":"cookie_consent_portal","id":18,"purposes_ids":["1"]},{"id":19,"name":"view_knd_login","purposes_ids":["3"]},{"purposes_ids":["3"],"id":20,"name":"survey_server_login"},{"purposes_ids":["2"],"name":"webtrekk","id":21},{"name":"affiliate_portal","id":22,"purposes_ids":["1"]},{"purposes_ids":["2"],"id":23,"name":"online_nps"},{"purposes_ids":["4"],"id":24,"name":"youtube"},{"purposes_ids":["4"],"name":"mouseflow","jentis_name":"mouseflow","id":25},{"purposes_ids":["4"],"name":"trustpilot","id":26},{"purposes_ids":["4"],"name":"tune","jentis_name":"tune_dev_hub","id":27},{"name":"tiktok","jentis_name":"tiktok","id":28,"purposes_ids":["4"]},{"name":"spotify","id":29,"purposes_ids":["4"]},{"id":30,"name":"jentis","purposes_ids":["2"]},{"id":31,"name":"adrecord","purposes_ids":["4"]},{"purposes_ids":["4"],"name":"bing","id":32},{"id":33,"name":"linkedin","purposes_ids":["4"]}],
        "purposes":[{"name":"notwendig","id":1},{"id":2,"name":"analyse"},{"id":4,"name":"partner"}],
        "vendorListVersion":"4"
    }
};



var alwaysReload = false;
export const apiUrls = {
    'de': '/orca/trkn/ger/',
    'nl': '/orca/trkn/dut/',
    'dut': '/orca/trkn/dut/',
    'es': '/orca/trkn/spa/',
    'fr': '/orca/trkn/fre/',
    'gb': '/orca/trkn/eng/',
    'uk': '/orca/trkn/eng/',
    'en': '/orca/trkn/eng/',
    'sv': '/orca/trkn/swe/',
    'se': '/orca/trkn/swe/',
    'technik': '/orca/trkn/ger/',
};
var noDeactivatedContentClass = document.querySelectorAll('body.noconsent').length == 0;
var showConsentByUrlParameter = window.location.href.indexOf('?consent') != -1 || window.location.href.indexOf('&consent') != -1;
var consentButtons = document.querySelectorAll('[data-consent-banner="show"]');
/**
 * get matching orca api url
 * @param {object} ALL_API_URLS of available orca urls
 * @param {object} host current host
 * @returns {string} orca url
 */
export const setOrcaApi = function (ALL_API_URLS, host) {
    var orcaApi = ALL_API_URLS['technik'];
    if (ALL_API_URLS) {
        const isD = host.match(/^[a-z]{2}-(dev|qs)/);
        const firstPart = host.split('.')[0];
        if (host.indexOf('technik') != -1) {
            const isM = firstPart.match(/(-[a-z]{2,3}\d*$|^[a-z]{2}-)/)
            if(isM){
                orcaApi = ALL_API_URLS[isM[0].replace('-', '').replace(/\d+/, '')];
            }
        } else
        if(isD){
            const mandant = firstPart.split('-')[0];
            orcaApi = ALL_API_URLS[mandant];
        } else {

            Object.keys(ALL_API_URLS).forEach(function(url) {
                if (host.split('.').indexOf(url) != -1) {
                    orcaApi = ALL_API_URLS[url];
                }
            });
        }
    }
    return orcaApi;
}
var orcaApi = setOrcaApi(apiUrls, window.location.host); // or utils?
/**
 * removing css classes to given element
 * @param {HTMLElement} dom element
 * @param {string} css css classes
 */
var removeClass = function(dom, css){ // ✅✅
    dom.querySelectorAll('.' + css).forEach(function(item){
        item.classList.remove(css);
    });
}
/**
 * handles alternative clicking
 * @param {any} e event to use
 * @returns {boolean} is is alternative click
 */
var isAlternativeClick  = function(e){ // ✅✅
    return (e.key === 'Enter' || e.key === ' ' || e.button === 0);  // left mouse button
}
/**
 * follows link or button
 * @param {any} e event to follow
 */
var followLink = function(e){ // ✅✅
    if(isAlternativeClick(e)){
        var element = e.target || e.srcElement;
        var url = element.getAttribute('data-url');
        if (url !== null && url !== '') {
            window.open(url, '_blank'); // link
        } else {
            if(e.type === 'keypress'){
                triggerEvent('click', element);
            }
        }
    }
}
/**
 * deactivating clickkin of overlay
 * @returns {boolean} to be deactivated
 */
var deactivateOverlayClick = function(){ // ✅✅
    var element = document.querySelector('.consent');
    return element && element.classList.contains('hidden') ? true : false;
}
/**
 * remove outline frome one element
 * @param {HTMLElement} dom element to be handeled
 */
var removeOutline = function(dom){ // ✅✅
    removeClass(dom, 'ele-checkbox--focused');
    removeClass(dom, 'link--a11y--focused');
}
/**
 * remove outline of a checkbox element
 * @param {any} e event to be handled
 */
var removeCheckboxOutline = function(e, wrapper){ // ✅✅
    wrapper.classList.remove('consent__wrapper--no-focus-highlight'); // TODO
    if (e.key === 'Tab') {
        var element = e.currentTarget;
        element.classList.remove('ele-checkbox--focused');
    };
}
/**
 * triggering specified event on element
 * @param {string} eventType name of event
 * @param {HTMLElement} element element the event to be dispatched
 */
var triggerEvent = function(eventType, element){ // ✅✅
    var event = new Event(eventType);
    element.dispatchEvent(event);
}
/**
 * special for SE to trigger moreLess-toggle
 * @param {string} eventType  name of event
 * @param {HTMLElement|null} element element the event to be dispatched
 */
var triggerClickMoreLess = function(eventType, element){ // ✅✅
    var event = new MouseEvent(eventType, {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      if(element) element.dispatchEvent(event);
}
/**
 * activates script blocks
 * @param {number|string} vendor
 */
var updateAppElements = function (vendor) { // ✅✅
    // tag must be a script tag with data-vendorid attribute and type opt-in
    var vendorNodes = document.querySelectorAll('script[type="opt-in"][data-vendorid="' + vendor + '"]');
    vendorNodes.forEach(function(currentElement) {
        var parentElement = currentElement.parentElement;
        var newElement = document.createElement('script');
        setElementsAttributes(currentElement, newElement, vendorNodes);
        if(parentElement){
            parentElement.insertBefore(newElement, currentElement);
            parentElement.removeChild(currentElement);
        }
    });
};
/**
 * handle promise request
 * @param {string} url
 * @param {string} method
 */
var getUserDataWithPromise = function sendRequest(url, method) { // ✅✅
    /** @type {!XMLHttpRequest} */
    var xhr = new XMLHttpRequest;
    return new Promise(function (resolve, reject) {
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.responseText);
                } else {
                    reject('Error, status code = ' + xhr.status);
                }
            }
        };
        xhr.open(method, url, true);
        /** @type {boolean} */
        xhr.withCredentials = true;
        xhr.send();
    });
};
/**
 * filter items on given parameter
 * @param {NodeListOf} items list of items
 * @param {string} selector selctor to be seleted
 * @param {string} antiSelctor selctor NOT to be selected
 * @returns
 */
var filterItems = function(items, selector, antiSelctor){ // ✅✅
    var itemsArray = Array.from(items);
    return itemsArray
        .filter(function(item){return filterItem(selector, item);} )
        .filter(function(item){return !filterItem(antiSelctor, item);});
}

/**
 * filter item by given selector
 * @param {*} selector
 * @param {HTMLElement} item
 * @returns
 */
var filterItem = function(selector, item){ // ✅✅
    var isID = selector[0] === '#';
    return isID ? '#' + item.getAttribute('id') === selector :
    item.classList.contains(selector.replace('.', '')) == true;

}
/**
 * handles submit and closing
 * @param {HTMLElement} wrapper wrapper item around
 * @param {string} selector selctor to be filted
 * @param {NodeListOf} items list of items
 * @param {string} purpose new purpose string
 */
var submitAndClose = function(wrapper, selector, items, purpose){ // ✅✅
    var buttons = Array.from(items)
        .filter(function(item){return !item.classList.contains('consentSettings');})
        .filter(function(item){return !item.getAttribute('data-url');})
        .filter(function(item){return item.getAttribute('id') !== 'consentBack';})
        .filter(function(item){return filterItem(selector, item);});
    buttons.forEach(function (button) {
        button.addEventListener('click', function () {
            var newPurpose = purpose;
            if(purpose !== ''){
                newPurpose  = purpose;
                setPurposeFromString(wrapper, purpose);
            } else {
                newPurpose = getPurposeStringFromCheckboxes(wrapper);
            }
            consenthide();
            setConsent(newPurpose);
        }, false);
    });
};
/**
 * get the list of vendors to be updated at elements
 * @param {Array<number|string>} vendors_as_list
 */
var activateByVendorID = function(vendors_as_list){ // ✅✅
    vendors_as_list.forEach(function (vendorID) {
        updateAppElements(vendorID);
    });
}

/**
 * udpate jentis script tag
 * @param {*} container
 * @param {*} newAttributes
 */
var updateJentisScriptTag = function(container, newAttributes, purpose){
    var containerData = container.innerHTML;

    var newText = `
    // # START JENTIS
    ${newAttributes}
    // # END JENTIS
        `;
    // Definiere die zu ersetzenden Teile des Musters
    var pattern = /\/\/ # START JENTIS([\s\S]*?)\/\/ # END JENTIS/;
    // Führe die Ersetzung durch
    var replacedText = containerData.replace(pattern, (match, p1) => {
        return newText.trim(); // Hier wird das neue Textstück eingefügt
        });
    // console.log(replacedText)
    container.innerHTML = replacedText;
    var dataType = container.getAttribute('data-type');
    if(dataType){
        container.setAttribute('type', dataType);
        container.setAttribute('data-jentis-init-listener-set', 'true');
        container.setAttribute('data-jentis-purpose-init', purpose);
    }
}


/**
 * get the list of vendors to be updated at elements
 * @param {Array<any>} vendors_as_list
*/
var activateJentis = function(vendors_as_list, purpose){ // ✅✅

    var jentisEngineLoaded = Object.prototype.hasOwnProperty.call(window, 'jentis') && window.jentis.consent && window.jentis.consent.engine;
    var containerData = document.querySelector('script[data-container-id="jentis"]');
    if(containerData){

        // get attributes
        var newAttributes = '';
        var newAttributesObject = {};
        for(var i = 0; i < vendors_as_list.length; i++){
            var vendor = vendors_as_list[i];
            if(vendor.jentis_name){
                newAttributesObject[vendor.jentis_name] = true;
                newAttributes += `"${vendor.jentis_name}": true,\n`;
            }
        }
        if(!jentisEngineLoaded){
            updateJentisScriptTag(containerData, newAttributes, purpose);
        } else {
            if(JSON.stringify(newAttributesObject) === '{}'){
                jentis.consent.engine.DenyAll();
            } else {
                jentis.consent.engine.setNewVendorConsents(newAttributesObject);
            }
        }
    }
}
/**
 * converts a string to json
 * @param {string} result
 * @returns {object} json data
 */
var getJSONFromString = function(result){ // ✅✅
    return result.length > 0 ? JSON.parse(result) : undefined;
}
/**
 * creates specified cookie
 * @param {string} name name of the cookie
 * @param {string} value  value of the cookie
 * @param {number} [days] optional number of valid days (default 365)
 */
var createCookie = function (name, value, days) { // ✅✅
    var date = new Date;
    var daysToExpire = days || 365;
    var day = 24 * 60 * 60 * 1000;
    var cookieExpire = date.setTime(date.getTime() + daysToExpire * day);
    var cookieExpireDate = new Date(cookieExpire);
    var strCookieExpire = cookieExpireDate.toUTCString();
    var isS = window.location.host.match(/(\.strato\.[a-z]{2,3}|strato-hosting\.co\.uk)/);
    var isD = window.location.host.match(/(\d+)/);
    var isL = window.location.host.match(/www\./);
    writeCookie(name, value, strCookieExpire, '/');
};
/**
 *
 * @param {boolean} alwaysReload if page should be reloaded after process
 * @param {string} purpose purposeString
 * @param {object} consentData data from API or fallback
 * @param {string} COOKIE_NAME name of the cookie to be handled
 * @param {boolean} hasORCA if ORCA is available
 */
var handleConsentFeedback = function(alwaysReload, purpose, consentData, COOKIE_NAME, hasORCA){ // ✅✅
    var location = window.location;
    var onBuy = location.pathname.split('/').indexOf('buy') != -1;
    var doReload = alwaysReload || onBuy;
    setDataConsentToBody(purpose);
    var vendors_as_list = getVendorIdsFromAPI(purpose, consentData)
    var jentis_list = getJentisIDsFromAPI(purpose, consentData);
    // wenn man nicht auf strato ist, dann fallback cookie
    if(!hasORCA){
        createCookie(COOKIE_NAME, cookieBakerie(purpose, consentData));
    }
    activateByVendorID(vendors_as_list);
    activateJentis(jentis_list, purpose);
    if (doReload) {
        window.location.reload();
    }
    fireCustomBodyEvent('consent_changed'); // consent set/change for A/B
}
/**
 * trigger and handle request to consent API
 * @param {string} purpose 4 digit string representing the purpose of consent
 */
var setConsent = function (purpose) { // ✅✅
    getUserDataWithPromise(orcaApi + purpose, 'post').then(function (result) {
        var consentData = getJSONFromString(result);
        handleConsentFeedback(alwaysReload, purpose, consentData, COOKIE_NAME, true);

    }, function () { // FALLBACK
        handleConsentFeedback(alwaysReload, purpose, MOCK_RESPONSE, COOKIE_NAME, false);
    });
};
/**
 *
 * @param {string} key name of cookie
 * @param {string} value value of the cookie
 * @param {string} strCookieExpire date when cookie should expire
 * @param {string|any} path path of cookie
 * @param {string|any} [domain] domain of cookie
 */
var writeCookie = function(key, value, strCookieExpire, path, domain){ // ✅✅
    var str = `${key}=${value}; expires=${strCookieExpire}; `;
    str += path && path !== undefined ? ` path=${path}` : '';
    str += domain && domain !== undefined ? ` domain=${domain}` : '';
    document.cookie = `${str}`;
}
/**
 * deletes specified cookie
 * @param {string} [name] optional cookie name
 */
var deleteCookie = function (name) { // ✅✅
    name = name || COOKIE_NAME;
    var setExpired = '; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/';
    document.cookie = name + '=' + setExpired;
};
/**
 * reads content of cookiecookie
 * @param {string} [name] optional cookie name
 * @return {string} decoded cookie content
 */
var readCookie = function (name) { // ✅✅
    name = name || COOKIE_NAME;
    // no cookie found
    if (document.cookie.indexOf(name) == -1) {
        return '';
    }
    // cookie found
    var cookies = document.cookie.split('; ');
    var result = '';
    cookies.forEach(function (cookie) {
        var parts = cookie.split('=');
        var COOKIE_NAME = parts[0];
        var cookieValue = parts[1];
        if (COOKIE_NAME === name) {
            result = cookieValue;
        }
    });
    return decodeURIComponent(result);
};
/**
 * get list of vendor IDs returns vendors array e.g. ['1', '2', ...]
 * @return {Array<string>}
 */
var readCookieVendorIds = function () { // ✅✅
    return readCookiePart(3);
};
/**
 * read specified part of cookie
 * @param {number} part content part of cookie (divided by a delimiter char)
 * @param {string} [delimiter] delimiter character
 * @param {string} [name] name of the cookie
 * @return {Array<string>} comma seperated items of specified cookie part
 */
var readCookiePart = function (part, delimiter, name) { // ✅✅
    delimiter = delimiter || '_';
    name = name || COOKIE_NAME;
    part--; // because array starts at 0
    var consentCookieData = readCookie(name) || cookieValueFallback;
    var items = consentCookieData.split(delimiter) || [];
    if (items.length > 0 && items[part] !== '') {
        var returnValue = items[part].split(','); // split by comma
        return returnValue;
    }
    return []; // e.g. when items[part] = ['']
};

/**
 * detects if cookie has consent
 * @param {string} [name] optional cookie name
 * @return {boolean}
 */
var hasConsent = function (name) { // ✅✅
    name = name || COOKIE_NAME;
    return (document.cookie.indexOf(name) > -1);
};
/**
 * handling opt out
 * @param {string} [name] optional cookie name
 */
var optOut = function (name) { // ✅✅
    name = name || COOKIE_NAME;
    deleteCookie(name);
    consentShow();
};
/**
 * read the cookie consent purpose e.g. '1111'
 * @return {string} consent purpose
 */
var readCookiePurposeIds = function () { // ✅✅
    return readCookiePart(2)[0];
};
/**
 * get the new layer element
 * @param {string} type name of the layer
 * @param {boolean} isNew is it new or old layer
 * @returns {HTMLElement|null} new layer element
 */
var getLayer = function(type, isNew) { // ✅✅
    var id = LAYERS
        .filter(function(item){ return isNew ? item.name === type : item.name !== type;})
        .map(function(item){ return item.id; });
    return document.getElementById(id[0])
}
/**
 * zeigt die Consenteinstellungen an
 * @param {boolean} doFocusFirstElement go to first element
 * @param {string} type typeof layer
 * @param {HTMLElement} wrapper consent wrapper
 */
var switchLayer = function (doFocusFirstElement, type, wrapper) { // ✅✅
    var layerNew = getLayer(type, true);
    var layerOld = getLayer(type, false);
    if (layerOld && layerNew) {
        wrapper.classList.remove('consent__wrapper--no-focus-highlight');
        consenttextclose();
        layerOld.classList.add('hidden');
        layerNew.classList.remove('hidden');
          /** @type {HTMLElement|null} */
        var firstItem = layerNew.querySelector('.link--a11y');
        if(firstItem){
            firstItem.focus();
        }
    }
    if(type === 'settings' && hasConsent()){
        var purpose = readCookiePurposeIds();
        setPurposeFromString(wrapper, purpose);
    }
};
/**
 * removing hide class
 * @param {HTMLElement} wrapper consent wrapper element
 */
var removeHideClass = function(wrapper){ // ✅✅
    var body = document.querySelector('body');
    var element = wrapper.querySelector('.consent');
    if(wrapper && body && element){
        element.classList.remove('hidden');
        body.classList.add('noscroll');
    }
    wrapper.focus();
}
/**
 * get path to consent txt file
 * @param {string} htmlLang language code
 * @param {string} htmlLang country code
 * @return {string} path to consent txt file
 */
var getConsentTxtPath = function(htmlLang, countryCode) {
    var id = 'gb';
    if(HTML_LANG){
        id = htmlLang;
    } else if(countryCode){
        id = countryCode;
    }
    return CONSENT_TXT[id];
}
/**
 * create banner from consent.txt
 * @param {HTMLElement} wrapper consent wrapper element
 */
var createNewBannerFromTxt = function(wrapper){ // ✅✅
    var xhttp = new XMLHttpRequest();
    var dataCountryCode = wrapper.getAttribute('data-country-code');
    var countryCode = dataCountryCode !== 'se' ? '_' + dataCountryCode : '';
    var path = getConsentTxtPath(HTML_LANG, countryCode);
    var cacheBuster = '?v=' + new Date().getTime();
    xhttp.open('GET', path + cacheBuster, true);
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            if(wrapper) wrapper.innerHTML = this.responseText;
            consentBannerAction(wrapper);
            removeHideClass(wrapper);
            fireCustomBodyEvent('consentElement');
        }
    };
    xhttp.send();
}
/**
 * open consent modal
 * @returns
 */
var consentShow = function () { // TODO: which wrapper // ✅
    var body = document.querySelector('body');
    /** @type {HTMLElement|null} */
    var wrapper = document.querySelector('.consent__wrapper');

    if (wrapper && body) {
        var element = wrapper.querySelector('.consent');
        // bugfix for POR-11855 (a11y) & POR-12277
        body.insertBefore(wrapper, body.childNodes[0]); // TODO: if already exists
        wrapper.setAttribute('tabindex', '0'); // a11y
        if (element) { // show existing banner
            removeHideClass(wrapper);
        } else {
            createNewBannerFromTxt(wrapper);
        }
    } else { // log a warning
        console.warn('Consent Banner not found');
        return;
    }
};
/**
 * closes consent modal
 */
var consenthide = function () { // ✅✅
    consenttextclose();
    var body = document.querySelector('body');
    var consentLayers = document.querySelectorAll('.consent');
    consentLayers.forEach( function(consentLayer) {
        consentLayer.classList.add('hidden');
    });
    if(body) body.classList.remove('noscroll');
};
/**
 * closes all text accordions
 */
var consenttextclose = function () { // ✅✅
    var consentMoreLess = document.querySelectorAll('.consent__more-less');
    consentMoreLess.forEach(function (element) {
        element.classList.remove('consent__more-less--click');
        if(element.previousElementSibling !== null){
            element.previousElementSibling.classList.add('hidden');
        }
    });
};
/**
 * handle tooltips
 */
var tooltipp = function () { // ✅✅
    /** @type {NodeListOf<HTMLElement>} */
    var tooltipIcons = document.querySelectorAll('.consent__icon__info');
    tooltipIcons.forEach(function (icon) {
        var posTooltipp = icon.offsetLeft,
            maxPos = 0;
        /** @type {HTMLElement|null} */
        var consentText = document.querySelector('.consent--text');
        if(consentText){
            maxPos = consentText.offsetWidth - 230; // 230 on the 1/2 with of tooltip
        } else {
            return;
        }

        icon.classList.remove('consent__icon__info--left');
        icon.classList.remove('consent__icon__info--right');

        // 160 bezieht sich auf die CSS Breite des Tooltipps
        if (posTooltipp - 160 <= 0) {
            icon.classList.add('consent__icon__info--left');
        }
        if (maxPos - posTooltipp < 0) {
            icon.classList.add('consent__icon__info--right');
        }
    });
};
/**
 * get all items by layer
 * @param {Array<string>} focusItems css definition for focus items
 * @param {string} layer  current layer
 * @returns {NodeListOf} queried elements
 */
var getItemsByLayer = function(focusItems, layer){ // ✅✅
    var allItems = [];
    focusItems.forEach(function(element) {
        allItems.push('.consent ' + layer + ' ' + element);
    });
    return document.querySelectorAll(allItems.join());
};
/**
 * set purpose of each checkbox
 * @param {HTMLElement} wrapper consent wrapper
 * @param {string} purpose purpose string
 */
var setPurposeFromString = function(wrapper, purpose){ // ✅✅
    var checkboxes = wrapper.querySelectorAll('input');
    checkboxes.forEach(function(checkbox, i) {
        checkbox.checked = purpose[i] == '1';
    });
}
/**
 * get purpose string from checkboxes
 * @param {HTMLElement} wrapper consent wrapper
 * @returns {string} new purpose string
 */
var getPurposeStringFromCheckboxes = function(wrapper){ // ✅✅
    var purposes = [],
    checkboxes = wrapper.querySelectorAll('input');
    checkboxes.forEach(function(checkbox, index) {
        purposes[index] = index === 0 ? '1' : checkbox.checked ? '1' : '0';
    });
    return purposes.join('');
};
/**
 * loading new layer
 * @param {string} type name of the layer
 * @param {any} event event to behandled
 * @param {HTMLElement} wrapper consent wrapper
 */
var loadPage = function(type, event, wrapper){ // ✅✅
    var doFollow = isAlternativeClick (event);
    var eventType = event.type;
    var focusFirstItem = (eventType === 'keypress');
    if(doFollow){
        switchLayer(focusFirstItem, type, wrapper);
    }
}
/**
 * Function to set consent to body data attribute
 * @param {string} purpose
 */
var setDataConsentToBody = function (purpose) { // ✅✅
    var body = document.querySelector('body');
    if(body) body.setAttribute('data-consent', purpose);
};
/**
 * loading no page layer on click
 * @param {HTMLElement} wrapper consent wrapper
 * @param {NodeListOf} elements  single elements to handle
 * @param {string} type name of page
 */
var loadPageOnClick = function(wrapper, elements, type){
    elements.forEach(function(element){ // ✅✅
        ['keypress', 'mouseup'].forEach(function(eventType){
            element.addEventListener(eventType, function(event){
                loadPage(type, event, wrapper);
            });
        });
    });
}
/**
 * handle switch toggle
 * @param {any} e event which toggles state
 */
var toggleSwitchState = function (e) { // ✅✅
    if (e.key === 'Enter' || e.key === ' ') {
        // parent works also for SE
        var input = this.parentElement.querySelector('input');
        if(input){
            triggerEvent('click', input);
            var id = '#' + input.getAttribute('id');
            if(id){
                input.checked = !input.checked;
            }
        }
    }
}
/**
 * set state of being clicked
 * @param {any} e event to handle
 */
var setClickState = function(e){ // ✅✅
    var hasClicking = this.getAttribute('data-clicking');
    if(e.button === 0){ // left mouse button
        hasClicking ?
            this.removeAttribute('data-clicking') :
            this.setAttribute('data-clicking', 'true');
    }
};
/**
 * set state of being focues
 */
var setFocusState = function () { // ✅✅
    if(this.getAttribute('data-clicking') !== 'true'){ // no focus on click
        this.classList.add('ele-checkbox--focused'); // highlighting toggles
    }
}
/**
 * trigger toggling of text accordions
 * @param {HTMLElement} element wrapper element
 * @param {string} selector which elements should be quereid
 * @param {any} event events to handle
 */
var triggerMoreLessToggle = function(element, selector, event){ // ✅✅
    var buttons = element.querySelectorAll(selector); // buttons more/less
    // a11y: trigger click event on enter for collapse element
    buttons.forEach(function(button) {
        button.addEventListener(event, function(e){
            if (e.key === 'Enter' || e.key === ' ') {
                this.setAttribute('data-clicked', 'true');
                triggerClickMoreLess('click', this.parentElement); // for SE
            }
        });
    });
}
/**
 * toggle text accordions
 */
var toggleMoreLess = function(){ // ✅✅
    var buttons = this.querySelectorAll('.link--a11y'); // buttons more/less
    if (!this.classList.contains('consent__more-less--click')) {
        consenttextclose();
    }
    this.classList.toggle('consent__more-less--click');
    if(this.previousElementSibling !== null){
        this.previousElementSibling.classList.toggle('hidden');
    }
    var wasClick = false;
    buttons.forEach(function(button) { // detect if one button was mouseclicked
        if(button.getAttribute('no-highlight-on-click')){
            wasClick = true;
        }
    });
    var isDifferentText = buttons.length === 2;
    buttons.forEach(function(button) { // re-focus
        if(wasClick){ // to disable highlighting on mouseclick
            button.setAttribute('no-highlight-on-click', 'true');
        }
        // re-focus when multiple texts in toggle
        if(isDifferentText && !button.getAttribute('data-clicked')){
            button.focus();
        } else {
            button.removeAttribute('data-clicked');
        }
    });
}
/**
 * select from all items back and forth the next one
 * @param {NodeListOf} items
 * @param {any} e event to be handled
 */
var getFirstOrLastItem = function(items, e){ // ✅✅
     var isTabPressed = (e.key === 'Tab' || e.keyCode === 9);
     var lastItem = items[items.length - 1];
    if(isTabPressed){
        if ( e.shiftKey ) /* shift + tab */ {
            if (document.activeElement ===  items[0]) {
                lastItem.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastItem) {
                var isVisible =  items[0].offsetParent !== null;
                var nexItem = isVisible ?  items[0] : items[1];
                nexItem.focus();
                e.preventDefault();
            }
        }
    }

}
/**
 * handles click on wrapper
 * @param {Event} event current event
 */
var handleWrapperClick = function(event){ // ✅✅
    switch(event.type){
        case 'mousedown': removeOutline(this); break;
        case 'keydown':
            this.classList.remove('consent__wrapper--no-focus-highlight');
            var elements = this.querySelectorAll('[no-highlight-on-click]')
            elements.forEach(function(element) {
                element.removeAttribute('no-highlight-on-click');
            });
            break;
        case 'click': deactivateOverlayClick(); break;
    }
}
/**
 * remove focus css class to disable highlighting
 * @param {Event & { target: HTMLElement }} event current event
 */
var removeFocusClass = function(event){ // ✅✅
    var target = /** @type {HTMLInputElement} */ event.target;
    if(target){
        target.classList.remove('link--a11y--focused');
    }
}
/**
 * set Attribute to avoid focus class
 * @param {Event & { target: HTMLElement }} event current event
 * @param {HTMLElement} wrapper consent wrapper
 */
var avoidFocusClass = function(event, wrapper){ // ✅✅
    wrapper.classList.add('consent__wrapper--no-focus-highlight');
    var target = /** @type {HTMLInputElement} */ event.target;
    if(target){
        target.setAttribute('no-highlight-on-click', 'true');
    }
};
/**
 * add Class on focus if its not a mouse event ([no-highlight-on-click])
 * @param {Event & { target: HTMLElement }} event current event
 * @param {HTMLElement} wrapper consent wrapper
 */
var addFocusClass = function(event, wrapper){ // ✅✅
    var target = /** @type {HTMLInputElement} */ event.target;
    if(!target.getAttribute('no-highlight-on-click') && !wrapper.classList.contains('consent__wrapper--no-focus-highlight')){ // just not clicked elements
        target.classList.add('link--a11y--focused');
    }
    var allClicked = document.querySelectorAll('[no-highlight-on-click]');
    allClicked.forEach(function(elementFound) {
        elementFound.removeAttribute('no-highlight-on-click');
    });
}
/**
 * does a lot of stuff, we need to refactor this
 * @param {HTMLElement} wrapper consent wrapper
 */
var consentBannerAction = function (wrapper) { // ✅✅
    var buttonsSettings = wrapper.querySelectorAll('.consentSettings');
    var buttonsBack = wrapper.querySelectorAll('#consentBack');
    /** @type {NodeListOf} */
    var collapses = wrapper.querySelectorAll('.consent__more-less');
    var radioButtons = wrapper.querySelectorAll('.ele-checkbox');
    var buttonTooltips = document.querySelectorAll('.consent__icon__info');
    var actionItems = wrapper.querySelectorAll('.btn--a11y, .consent__link.link--a11y');
    var consentLinks = filterItems(actionItems, '.consent__link', '.consentSettings');
    if (wrapper) {
        loadPageOnClick(wrapper, buttonsSettings, 'settings');
        loadPageOnClick(wrapper, buttonsBack, 'default'); // back

        // a11y: trigger click event on enter for links / buttons
        consentLinks.forEach(function(element){
            element.addEventListener('keypress', followLink, false);
            element.addEventListener('mousedown', function(event){ return avoidFocusClass(event, wrapper), true});
            element.addEventListener('mouseup', followLink, false);
        });
        // show and hide text inside collapse element
        collapses.forEach(function(collapse){
            collapse.addEventListener('mousedown', function(event){ return avoidFocusClass(event, wrapper), true});
            collapse.addEventListener('click', toggleMoreLess, false);
            triggerMoreLessToggle(collapse, '.link--a11y', 'keypress');
        });

        // remove checkbox focusing
        var items = getItemsByLayer(FOCUS_ITEMS, '');
        items.forEach(function(element){
            element.addEventListener('keydown', function(event){ return removeCheckboxOutline(event, wrapper), true});
            element.addEventListener('mousedown', function(event){ return avoidFocusClass(event, wrapper), true});
            element.addEventListener('focus', function(event){ return addFocusClass(event, wrapper), false});
            element.addEventListener('focusout', removeFocusClass, false);
        });

        var wrapperEvents = ['mousedown', 'click', 'keydown'];
        wrapperEvents.forEach(function(eventType){
            wrapper.addEventListener(eventType, handleWrapperClick);
        });

        // a11y: handling last and first focusable element on keydown
        var layers = LAYERS.map(function(item){ return '#' + item.id});
        layers.forEach(function(layer) {
            var subItems = getItemsByLayer(FOCUS_ITEMS, layer);
            subItems.forEach(function(item) {
                item.addEventListener('keydown', function(e){
                    getFirstOrLastItem(subItems, e);
                });
            });
        });

        // a11y: trigger click event on enter for on/off radio buttons
        radioButtons.forEach(function(radioButton){
            radioButton.addEventListener('focus', setFocusState, true);
            radioButton.addEventListener('mousedown', setClickState, true);
            radioButton.addEventListener('mouseup', setClickState, true);
            radioButton.addEventListener('keypress', toggleSwitchState, true);
        });

        submitAndClose(wrapper, '.consentAgree', actionItems, '1111'); // zustimmen & schließen
        submitAndClose(wrapper, '#consentDisagree', actionItems, '1000'); // ablehnen & schließen
        submitAndClose(wrapper, '#consentDisagreeButton', actionItems, '1000'); // ablehnen & schließen
        submitAndClose(wrapper, '#consentAddAll', actionItems, '1111'); // zustimmen & schließen
        submitAndClose(wrapper, '#consentSubmit', actionItems, ''); // aktuelles Setting und schließen

        // TODO: why this function? POR-12106
        buttonTooltips.forEach(function(element) {
            element.addEventListener('mouseover', tooltipp);
        });
        if (buttonTooltips.length > 0) {
            window.addEventListener('resize', tooltipp);
        }
    } else {
        return;
    }
}
/**
 * set attributes on element
 * @param {any} node element to handle
 * @param {any} newElement
 * @param {NodeListOf} vendorNodes list of vendors
 */
var setElementsAttributes = function(node, newElement, vendorNodes) { // ✅✅
    var attributes = [
        {'type': node.dataset.type},
        {'src': node.src},
        {'name': node.dataset.name},
        {'innerText': node.innerText},
        {'text': node.text},
        {'class': node.class},
        {'id': node.id},
        {'defer': node.defer},
    ];
    attributes.forEach(function(value) {
        if(value){
            var key = Object.keys(value)[0];
            if(key && value[key]){
                newElement[key] = value[key];
            }
        }
    });

    if (node.dataset.vendorid) {
        newElement.dataset.vendorid = node.dataset.vendorid;
    }

    if (vendorNodes.length > 1) {
        newElement.async = false;
    }
    if (undefined != node.dataset.src) {
        newElement.src = node.dataset.src;
    }
}

/**
 * triggers a custom event on body
 * @param {string} eventName
 */
var fireCustomBodyEvent = function(eventName) { // ✅✅
    var body = document.querySelector('body');
    var event;
    if (window.CustomEvent && typeof window.CustomEvent === 'function') {
        event = new CustomEvent(eventName, {});
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, true, true, {});
    }
    if(body) body.dispatchEvent(event);
}
/**
 * reads date from cookie content
 * @param {string} cookieContent
 * @returns {object} datetime object
 */
var getCookieDate = function(cookieContent){ // ✅✅
    return {
        year: cookieContent.substring(0, 4),
        month: cookieContent.substring(5, 7),
        day: cookieContent.substring(8, 10),
        time: cookieContent.substring(10,20)
    }
}
/**
 * check if it is fallback cookie
 * @param {string} cookieContent content of cookie
 * @returns {boolean} true if it is fallback cookie
 */
var isFallbackCookie = function(cookieContent) { // ✅✅
    var date = getCookieDate(cookieContent);
    return (date.year == '0000' && date.month == '00' && date.day == '00');
}
/**
 * check if the cookie is outdated (as written in cookie content)
 * @returns {boolean} true if it is outdated
 */
var cookieIsOutdated = function(){ // ✅✅
    var cookieContent = readCookie();
    if (isFallbackCookie(cookieContent)) {
        return false; // never true for fallback Cookie
    }
    var cookieDate = new Date(cookieContent.split('_')[0]);
    return new Date(cookieDate) < new Date(lastUpdated);
}
/**
 * getting allowed purposes
 * @param {string} purpose purpose string
 * @returns
 */
var getAllowedPurposes = function(purpose){ // ✅✅
    var purposeIds = purpose.split(''); // convert purposeString to array
    var permittedPurposes = [];
    purposeIds.forEach(function (purposeID, index) {
        if (purposeID == '1') {
            permittedPurposes.push(index + 1 + '');
        }
    });
    return permittedPurposes;
}
/**
 * Function to get permmitted vendor ids from the mocked apiResponse and return them as array
 * @param {string} purpose
 * @param {object} apiResponse
 * @returns {Array<number>} vendor IDS
 */
var getVendorIdsFromAPI = function(purpose, apiResponse) { // ✅✅
    purpose = purpose || DEFAULT_PURPOSE;
    apiResponse = apiResponse || MOCK_RESPONSE;

    var vendorIds = [];
    var permittedPurposes = getAllowedPurposes(purpose);
    var vendors = apiResponse.data_cookie_consent.vendors;
    for (var key in vendors) {
        const vendor = vendors[key];
        var purposeIDs = vendors[key].purposes_ids;
        var vendorID = vendors[key].id;
        // check if purposeID in in allowed purposes
        if (purposeIDs.some(function(purposeID) {
            return permittedPurposes.includes(purposeID);
        }
        )) {
            vendorIds.push(vendorID);
        }
    }
    return vendorIds;
}
/**
 * Function to get permmitted vendor jentis names from the mocked apiResponse and return them as array
 * @param {string} purpose
 * @param {object} apiResponse
 * @returns {Array<any>} vendors
 */
var getJentisIDsFromAPI = function(purpose, apiResponse) { // ✅✅
    purpose = purpose || DEFAULT_PURPOSE;
    apiResponse = apiResponse || MOCK_RESPONSE;

    var vendorList = [];
    var permittedPurposes = getAllowedPurposes(purpose);
    var vendors = apiResponse.data_cookie_consent.vendors;
    for (var key in vendors) {
        const vendor = vendors[key];
        var purposeIDs = vendors[key].purposes_ids;
        var vendorID = vendors[key].id;
        // check if purposeID in in allowed purposes
        if (purposeIDs.some(function(purposeID) {
            return permittedPurposes.includes(purposeID);
        }
        )) {
            vendorList.push(vendor);
        }
    }
    return vendorList;
}
/**
 * function to generate the consent cookie content depending on the purposeString
 * @param {string} purpose
 * @param {object} apiResponse
 * @returns {string} cookie value
*/
var cookieBakerie = function(purpose, apiResponse){
    purpose = purpose || '1000';
    apiResponse = apiResponse || MOCK_RESPONSE;
    //get date from apiData
    var date = apiResponse.data_cookie_consent.lastUpdated;
    var vendorIds = getVendorIdsFromAPI(purpose, apiResponse);
    //convert vendorIds to string
    var vendorIdsString = vendorIds.join(',');

    // TODO: cookieValueFallback
    var returnValue = '';
    returnValue = date + '_' + purpose + '_' + vendorIdsString;
    //encode uri returnValue
    returnValue = encodeURIComponent(returnValue);
    return returnValue;
}
if (consentWrapper) {
    consenthide();
    if (showConsentByUrlParameter === true) {
        consentShow();
    } else {
        if (noDeactivatedContentClass === true) {
            if (hasConsent('cookiecookie')) {
                document.addEventListener('DOMContentLoaded', function () {
                    var purposeIds = readCookiePurposeIds();
                    var purposeIdString = purposeIds;
                    setDataConsentToBody(purposeIdString);
                    var vendors_as_list = readCookieVendorIds();
                    activateByVendorID(vendors_as_list);

                    // need api data
                    getUserDataWithPromise(orcaApi + purposeIdString, 'post').then(function (result) {
                        var consentData = getJSONFromString(result);
                        handleConsentFeedback(alwaysReload, purposeIdString, consentData, COOKIE_NAME, true);

                    }, function () { // FALLBACK
                        handleConsentFeedback(alwaysReload, purposeIdString, MOCK_RESPONSE, COOKIE_NAME, false);
                    });
                }, false);
                if (cookieIsOutdated()) {
                    deleteCookie();
                    consentShow();
                }
            } else {
                consentShow();
            }
        } else {

        }
    }
}
// add event listener to all buttons calling the consent
consentButtons.forEach(function (element) {
    element.addEventListener('click', consentShow, false);
});
