/* eslint-disable max-lines-per-function */

document.addEventListener('DOMContentLoaded', async () => {
    try {
        // Server-Zeit aus HTTP-Header "Date" abrufen mit nocache
        const response = await fetch(`/favicon.ico?nocache=${Date.now()}`, { method: 'HEAD', cache: 'no-store' });
        const serverTime = new Date(response.headers.get('Date') || Date.now());

        //console.info('Server-Zeit:', serverTime);

        const currentDay = serverTime.getDay(); // Wochentag (0=Sonntag, 1=Montag, ...)
        const currentTime = serverTime.getHours() * 100 + serverTime.getMinutes(); // HHMM-Format

        // Geschäftszeiten: Mo-Fr 09:00-18:00, Sa 10:00-14:00
        const businessHours = [
            { days: [1, 2, 3, 4, 5], start: 800, end: 1800 } // Mo-Fr
        ];

        // Prüfen, ob aktuelle Zeit in Geschäftszeiten liegt
        const isBusinessHours = businessHours.some(({ days, start, end }) =>
            days.includes(currentDay) && currentTime >= start && currentTime <= end
        );

        if (!isBusinessHours) return; // Falls außerhalb der Geschäftszeiten, nichts tun
        // Funktion zum Kopieren von Attributen in ein neues Skript-Element
        const setElementsAttributes = (node, newElement) => {
            [
                { type: node.dataset.type || 'text/javascript' }, // Fallback auf Standard-JS-Typ
                { src: node.dataset.src || node.src },
                { name: node.dataset.name },
                { innerText: node.innerText },
                { text: node.text },
                { class: node.className },
                { id: node.id },
                { defer: node.defer }
            ].forEach(attr => {
                const key = Object.keys(attr)[0];
                if (key && attr[key]) {
                    newElement[key] = attr[key];
                }
            });
        };

        // Funktion zum Ersetzen von `<script type="businessHours">` mit echten `<script>`-Tags
        const updateAppElements = () => {
            const businessHourScripts = document.querySelectorAll('script[type="duringBusinessHours"]');
            businessHourScripts.forEach(currentElement => {
                const parentElement = currentElement.parentElement;
                const newElement = document.createElement('script');

                setElementsAttributes(currentElement, newElement, businessHourScripts);

                if (parentElement) {
                    parentElement.insertBefore(newElement, currentElement);
                    parentElement.removeChild(currentElement);
                }
            });
        };

        // Aufrufen der Funktion, um die Skripte während der Geschäftszeiten zu aktivieren
        updateAppElements();
    } catch (error) {
        console.error('Fehler beim Abrufen der Server-Zeit:', error);
    }
});
